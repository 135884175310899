import React from 'react'
// react plugin used to create google maps

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function ContactUs() {

  return (
    <>
      <div className="cd-section" id="connect">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg15.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h2 className="title mb-0">Get in Touch</h2>
                  <h4 className="description text-center">
                    Connect with me on the social networks and reach out!
                </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    className="btn-icon btn-neutral btn-footer mx-4"
                    color="link"
                    href="https://www.linkedin.com/in/antonio-franchini-40874070/" target="_blank"
                    size="lg"
                  >
                    <i className="fab fa-linkedin" style={{fontSize:'3rem'}}></i>
                  </Button>
                  <Button
                    className="btn-icon btn-neutral btn-footer mx-4"
                    color="link"
                    href="https://www.facebook.com/antonio.franchini.357" target="_blank"
                    size="lg"
                  >
                    <i className="fab fa-facebook-square" style={{fontSize:'3rem'}}></i>
                  </Button>
                  <Button
                    className="btn-icon btn-neutral btn-footer mx-4"
                    color="link"
                    href="https://github.com/antonio-franchini" target="_blank"
                    size="lg"
                  >
                    <i className="fab fa-github" style={{fontSize:'3rem'}}></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
