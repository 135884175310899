import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Headers() {
  // navbar collapses states and functions
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  const [navbarOpen2, setNavbarOpen2] = React.useState(false);
  const [navbarOpen3, setNavbarOpen3] = React.useState(false);
  // header 3 carousel states and functions

  return (
    <>
      {navbarOpen1 || navbarOpen2 || navbarOpen3 ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setNavbarOpen1(false);
            setNavbarOpen2(false);
            setNavbarOpen3(false);
          }}
        />
      ) : null}
      <div className="cd-section" id="home">
        <div className="header-1"></div>
        <div className="page-header header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(" + require("assets/img/pizzaiolo.jpg") + ")",
            }}
          ></div>
          <Container className="align-self-end">
            <Row >
              <Col className="ml-auto text-right pt-4" md="7">
                <h1 className="title">Antonio Franchini</h1>
                <h3 className="description mb-0">
                  Baking fresh software every day
                </h3>
                <div className="buttons">
                  <Button
                    className="btn-icon btn-neutral"
                    color="link"
                    href="https://www.linkedin.com/in/antonio-franchini-40874070/" target="_blank"
                    size="lg"
                  >
                    <i className="fab fa-linkedin"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-neutral"
                    color="link"
                    href="https://www.facebook.com/antonio.franchini.357" target="_blank"
                    size="lg"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-neutral"
                    color="link"
                    href="https://github.com/antonio-franchini" target="_blank"
                    size="lg"
                  >
                    <i className="fab fa-github"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Headers;
