import React from "react";

// reactstrap components
import {
  Card,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Blogs() {
  return (
    <>
      <div className="cd-section" id="timeline">
        <div className="blogs-1" id="blogs-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title text-center">What I have done so far</h2>
                <br></br>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/offerpad.jpg")}
                        ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <h3 className="category text-info mt-3">Offerpad</h3>
                      <p className="author">
                      Gilbert, Arizona, USA
                      </p>
                      <CardTitle tag="h3">
                        <h7>
                        Software Engineer (2020-Present)
                        </h7>
                      </CardTitle>
                      <p className="card-description">
                      I’m currently working as a full stack web developer at Offerpad. I focus on customer facing applications that enhance the experience of buying and selling homes. The typical stack is composed by a React frontend and .NET backend. Other tools include Azure, Git, Kubernetes, etc. I enjoy this fast paced work environment in which we adopt the Agile methodology.
                      </p>

                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row className="flex-column-reverse flex-md-row">
                    <Col md="7">
                      <h3 className="category text-info mt-3">
                      Georgia Insitute of Technology
                      </h3>
                      <p className="author">
                      Atlanta, Georgia, USA
                      </p>
                      <CardTitle tag="h3">
                        <h7>
                        M.S. in Computer Science (2016-2020)
                        </h7>
                      </CardTitle>
                      <p className="card-description">
                      This amazing program is providing me with a round knowledge of computing. My coursework includes algorithms, data structures, software development precess, architecture, security, quality, testing, computer networks and database design.
                      </p>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/gatech.png")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/cvs.jpg")}
                        ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <h3 className="category text-info mt-3">CVS Health</h3>
                      <p className="author">
                      Scottsdale, Arizona, USA
                      </p>
                      <CardTitle tag="h3">
                        <h7>
                        Software Engineer (2018-2020)
                        </h7>
                      </CardTitle>
                      <p className="card-description">
                      During my time at CVS, I worked as full stack web developer. My focus was internal applications for managing and processing huge amount of contract and payments data. The typical stack was composed by an Angular 2 frontend and a Spring Boot backend. Other tools included Docker, Git, Jenkins, Hibernate, Pivotal Cloud Foundry and much more.
                      </p>

                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row className="flex-column-reverse flex-md-row">
                    <Col md="7">
                      <h3 className="category text-info mt-3">
                      Garmin
                      </h3>
                      <p className="author">
                      Chandler, Arizona, USA
                      </p>
                      <CardTitle tag="h3">
                        <h7>
                        Software Engineer (2014-2018)
                        </h7>
                      </CardTitle>
                      <p className="card-description">
                      I had five fantastic years at Garmin. My main area was development of software for integrated flight decks avionics with particular focus on user interface. I was responsible on the entire software lifecycle from requirements to verification. I designed numerous new features as well as performing maintenance for products and functionality already in production.
                      </p>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/garmin.jpg")}
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/asu.jpg")}
                        ></img>
                      </div>
                    </Col>
                    <Col md="7">
                      <h3 className="category text-info mt-3">Arizona State University</h3>
                      <p className="author">
                      Tempe, Arizona USA
                      </p>
                      <CardTitle tag="h3">
                        <h7>
                        B.S.E. in Computer Systems Engineering (2011-2014)
                        </h7>
                      </CardTitle>
                      <p className="card-description">
                      ASU provided me with all the foundational knowledge of computers at 360 degrees. The program covered software as well as hardware and span all aspects of computing systems. The coursework included algorithms, data structures, theory of computation, programming languages, operating systems, computer networks, computer architecture, mathematics, circuits, embedded systems and even technology entrepreneurship.
                      </p>

                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Blogs;
