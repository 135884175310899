import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function AboutUs() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="section" id="more-about-me">        
          <div className="about-office mt-0 pt-0" >
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">When I'm not writing code...</h2>
                  <h4 className="description">
                  When I'm not developing software, I enjoy spending time with my family and friends, running, lifting weights, watching soccer, movies and eating good foods.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/church.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/food-vigilia.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/oz.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/avengers.jpg")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/running.jpg")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/saguaro.jpg")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/hike.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/cristallo.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/boats.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/roma.jpg")}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/italia2006.jpg")}
                  ></img>
                </Col>

              </Row>
            </Container>
          </div>
          </div>
      </div>
    </>
  );
}

export default AboutUs;
