import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Features() {
  return (
    <>
      <div className="cd-section" id="features">
        <div
          className="features-2 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg22.jpg") + ")",
          }}
        >
          <Container fluid>
            <Row>
              <Col className="mr-auto ml-auto" md="8">
                <h3 className="description">
                Check out my favorite TED talk by Simon Sinek, "How great leaders inspire action".
                </h3>
                <div>
                    <div style={{position:"relative", height:0, paddingBottom:"35%"}}>
                        <iframe src="https://embed.ted.com/talks/lang/en/simon_sinek_how_great_leaders_inspire_action"
                            width="256.2" height="144"
                            style={{position:"absolute",left:"50%",top:"50%",transform: "translate(-50%, -50%)",margin:"auto"}}
                            frameborder="0" seamless="" scrolling="no" allowfullscreen>
                        </iframe>
                    </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Features;
