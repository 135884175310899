/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <div>
            <ul>
              <li>
              Thanks for visiting my page, have a nice day!
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
